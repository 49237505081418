import { useState, useEffect } from 'react';
import Image from './Image.js';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from './Footer.js';

export default function Department() {
  const [isLoading, setIsLoading] = useState(true);
  const [departmentData, setDepartmentData] = useState({});
  const { name } = useParams();

  useEffect(()=>{
        axios
          .get(
            `https://xnltgcjvfxk3fb3nwvyujcbtji0ekkxw.lambda-url.ap-south-1.on.aws/api/Department/GetAllImagesByDepartment?department=${name}`
          )
          .then((response) => {
            console.log('afsdf');
            console.log(response.data)
            setDepartmentData(response.data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
  }, [name]);

  return (
    <div>
      {isLoading ? (
        // Show the loading spinner and blurred background
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div>
          {Object.keys(departmentData).length !== 0 ? (
            // Show the component with the fetched data
            Object.keys(departmentData).map((key) => (
              <Image key={key} data={{ Name: key, Data: departmentData[key] }} />
            ))
          ) : (
            // Show a message indicating that there is no data available
            <div className="no-data-container">
              <p className="no-data-message">No data available.</p>
            </div>
          )}
        </div>
      )}
      <Footer/>
    </div>
  );
}
