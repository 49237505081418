import '../css/header.css';

export default function Title() {
  return (
    <div className="title">
      <h1>
        Dutypedia <span className="title-span">@</span>JJMMC
      </h1>
    </div>
  );
}
