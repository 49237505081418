
export default function Image({data}) {
  const baseStr64 = data.Data;
  const imgSrc = 'data:image/jpg;base64,' + baseStr64;
  return (
    <div>
      <img className="image" src={imgSrc} alt={data.Name} />
    </div>
  );
}

