import BuyMeACoffeeButton from "./BuyMeACoffeeButton";
import '../css/footer.css';

export default function Footer() {
  return (
    <div className="footer">
      <p>© 2023 - Dutypedia by GT</p>
      {/* <BuyMeACoffeeButton/> */}
    </div>
  );
}
