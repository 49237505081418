import React from 'react';
import '../css/Department.css';
import data from '../Data/Data.json';
import { useNavigate } from 'react-router-dom';
import Title from './Title';
import Footer from './Footer';

export default function Home() {
  const navigate = useNavigate();

  const departments = data.map((item) => {
    return (
      <div className='button-container'>
        <button className="department-button" onClick={() => navigate(`/Department/${item.Name}`)}>
          {item.Name}
        </button>
      </div>
    );
  });

  return (
    <div>
      <Title/>
      {departments}
      <Footer/>
    </div>
  );
}
