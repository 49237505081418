import React from 'react';
import Home from './components/Home.js';
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Department from './components/Department.js';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/Department/:name" exact element={<Department />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
